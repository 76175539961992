import React, { useState, useRef, useEffect } from "react";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase/firebaseConfig";

import {
  PanelContainer,
  DragIndicator,
  TabContainer,
  Tab,
  PanelContent,
  CookieText,
  CenteredContainer,
  ResponsiveButton,
} from "./NavigationPanel.styled";
import Measure from "react-measure";

import linkImg from "../../assets/images/linkImg.webp";
import tutorial from "../../assets/images/tutorial.webp";
import playPractice from "../../assets/images/playPractice1.jpg";
import playToday from "../../assets/images/playToday1.jpg";
import aboutImg from "../../assets/images/aboutImg.webp";
import prs from "../../assets/images/prs.webp";

const NavigationPanel = () => {
  // State for panel open/close
  const [isOpen, setIsOpen] = useState(false);
  const [initialRenderDone, setInitialRenderDone] = useState(false);

  const [contentHeight, setContentHeight] = useState(0);

  const [currentTab, setCurrentTab] = useState("HOW TO PLAY");
  const panelContentRef = useRef(null);
  const panelContainerRef = useRef(null);

  const togglePanel = () => setIsOpen(!isOpen);
  // Function for preload
  const preloadImages = (images) => {
    images.forEach((src) => {
      const img = new Image();
      img.src = src;
    });
  };

  // Preload photos
  useEffect(() => {
    const imagesToPreload = [
      linkImg,
      tutorial,
      playPractice,
      playToday,
      aboutImg,
      prs,
    ];

    preloadImages(imagesToPreload);
  }, []);

  const handleInstagramClick = () => {
    logEvent(analytics, "Clicked_Instagram", {
      platform: "Instagram",
    });
  };

  const handleFacebookClick = () => {
    logEvent(analytics, "Clicked_Facebook", {
      platform: "Facebook",
    });
  };

  const handleXClick = () => {
    logEvent(analytics, "Clicked_X", {
      platform: "X",
    });
  };

  useEffect(() => {
    const savedState = localStorage.getItem("panelMinimized");

    if (savedState !== "true" && !initialRenderDone) {
      setTimeout(() => {
        setIsOpen(true);
        setInitialRenderDone(true);
      }, 100);
    }
  }, [initialRenderDone]);

  useEffect(() => {
    if (panelContentRef.current) {
      panelContentRef.current.scrollTo({ top: 0 });
    }
  }, [currentTab]);

  const handlePlayToday = () => {
    const currentUrl = window.location.href; // Trenutni URL
    const exampleUrl = "https://crypticdj.com/example";

    if (currentUrl === exampleUrl) {
      // Ako smo na example stranici
      const lastUrl = localStorage.getItem("lastGameUrl");

      if (
        lastUrl &&
        (lastUrl.includes("cryptic") || lastUrl.includes("local"))
      ) {
        window.location.href = lastUrl; // Vrati korisnika na sačuvani URL
      } else {
        console.log("No valid URL found, minimizing panel.");
      }
    }

    // U svakom drugom slučaju samo minimizuj panel
    setIsOpen(false);
  };

  const handlePlayExample = () => {
    const currentUrl = window.location.href;
    const exampleUrl = "https://crypticdj.com/example";

    // Ako smo već na example stranici, samo minimizuj panel
    if (currentUrl === exampleUrl) {
      setIsOpen(false);
      return;
    }

    // Sačuvaj trenutni URL u localStorage
    localStorage.setItem("lastGameUrl", currentUrl);

    // Preusmeri korisnika na example stranicu
    setIsOpen(false);
    window.location.href = exampleUrl;
  };

  // Swipe handling for tab switching
  const startX = useRef(0);
  const endX = useRef(0);

  const handleTouchStart = (e) => {
    startX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = (e) => {
    endX.current = e.changedTouches[0].clientX;
    const swipeDistance = startX.current - endX.current;

    if (isOpen) {
      if (swipeDistance > 50) {
        // Swipe left
        setCurrentTab((prevTab) =>
          prevTab === "HOW TO PLAY" ? "ABOUT" : prevTab
        );
      } else if (swipeDistance < -50) {
        // Swipe right
        setCurrentTab((prevTab) =>
          prevTab === "ABOUT" ? "HOW TO PLAY" : prevTab
        );
      }
    }
  };

  return (
    <PanelContainer
      ref={panelContainerRef}
      isOpen={isOpen}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <DragIndicator
        isOpen={isOpen}
        onClick={togglePanel}
        style={{
          marginTop: "4px",
        }}
      >
        {isOpen ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="20"
            viewBox="0 0 32 20"
            fill="none"
            stroke="currentColor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <polyline points="4 5 16 15 28 5" />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="20"
            viewBox="0 0 32 20"
            fill="none"
            stroke="currentColor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <polyline points="4 15 16 5 28 15" />
          </svg>
        )}
      </DragIndicator>

      <TabContainer>
        <Tab
          active={currentTab === "HOW TO PLAY"}
          onClick={() => {
            setCurrentTab("HOW TO PLAY");
          }}
        >
          HOW TO PLAY
        </Tab>
        <Tab
          active={currentTab === "ABOUT"}
          onClick={() => {
            setCurrentTab("ABOUT");
          }}
          style={{ width: "111px", justifySelf: "center", alignSelf: "center" }}
        >
          ABOUT
        </Tab>
      </TabContainer>
      <Measure
        bounds
        onResize={(contentRect) => {
          if (contentRect.bounds) {
            setContentHeight(contentRect.bounds.height); // Prati visinu sadržaja
          }
        }}
      >
        {({ measureRef }) => (
          <PanelContent
            ref={(el) => {
              panelContentRef.current = el; // Povezivanje sa tvojim ref-om
              measureRef(el); // Povezivanje sa Measure ref-om
            }}
          >
            {currentTab === "HOW TO PLAY" ? (
              <div>
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "10px",
                    marginBottom: "16px",
                    fontSize: "17px",
                  }}
                >
                  Hidden within the Titles and Artists of famous songs are words
                  that have some kind of link.
                </div>
                <div
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    marginBottom: "16px",
                    fontSize: "17px",
                  }}
                >
                  Guess the songs - then the link - in the quickest time
                </div>
                <div
                  style={{
                    textAlign: "left",
                    paddingTop: "16px",
                    paddingBottom: "10px",
                    fontSize: "15px",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    backgroundColor: "#251b5a",
                  }}
                >
                  Play a song, then start typing the song name{" "}
                  <strong>or the artist</strong> who had the hit and choose it
                  from the list.
                </div>
                <div
                  style={{
                    textAlign: "center",
                    paddingBottom: "16px",
                    backgroundColor: "#251b5a",
                  }}
                >
                  <img
                    src={tutorial}
                    alt="Tutorial"
                    style={{ width: "100%", maxWidth: "335px", height: "auto" }}
                  />
                </div>
                <div
                  style={{
                    textAlign: "left",
                    paddingTop: "5px",
                    paddingRight: "5px",
                    paddingBottom: "16px",
                    paddingLeft: "5px",
                    fontSize: "15px",
                    backgroundColor: "#251b5a",
                  }}
                >
                  If you don’t know a song, move on to another or Reveal - but
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    revealing adds 20 seconds
                  </span>{" "}
                  to your time.
                </div>
                <div
                  style={{
                    textAlign: "left",
                    paddingTop: "5px",
                    paddingRight: "5px",
                    paddingBottom: "16px",
                    paddingLeft: "5px",
                    fontSize: "15px",
                    backgroundColor: "#251b5a",
                  }}
                >
                  Once you’ve got at least 3 songs, guess the link within the
                  words you see in the Titles{" "}
                  <span style={{ fontStyle: "italic" }}>or Artists</span>...
                </div>
                <div
                  style={{
                    textAlign: "center",
                    paddingBottom: "0px",
                    backgroundColor: "#251b5a",
                  }}
                >
                  <img
                    src={linkImg}
                    alt="Link Image"
                    style={{ width: "100%", maxWidth: "335px", height: "auto" }}
                  />
                </div>
                <div
                  style={{
                    textAlign: "center",
                    paddingBottom: "16px",
                    fontSize: "13px",
                    backgroundColor: "#251b5a",
                    marginTop: "-10px",
                  }}
                >
                  (They won’t be this easy)
                </div>
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: "16px",
                    marginTop: "10px",
                    fontSize: "17px",
                    paddingLeft: "2px",
                    paddingRight: "2px",
                  }}
                >
                  Challenge your friends to beat your time for the same game
                  (they won’t need to install an app, just click on the link to
                  play in a browser).
                </div>
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: "16px",
                    fontSize: "15px",
                  }}
                >
                  There’s a new game every day...<br></br>so add Cryptic DJ to
                  your Home Screen!
                </div>
                <div
                  style={{
                    textAlign: "center",
                    paddingTop: "16px",
                    backgroundColor: "#251b5a",
                    marginBottom: "-20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "1px",
                      paddingBottom: "16px",
                    }}
                  >
                    <button
                      style={{
                        flex: 1,
                        border: "none",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        handlePlayToday();
                      }}
                    >
                      <img
                        src={playToday}
                        alt="Play Today"
                        style={{
                          width: "100px",
                          height: "auto",
                          borderRadius: "10px",
                        }}
                      />
                      <p
                        style={{
                          marginTop: "8px",
                          textAlign: "center",
                          fontSize: "14px",
                          lineHeight: "1.4",
                          color: "white",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          Play today’s game
                        </span>{" "}
                      </p>
                    </button>

                    <button
                      style={{
                        flex: 1,
                        border: "none",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        handlePlayExample();
                      }}
                    >
                      <img
                        src={playPractice}
                        alt="Play Practice"
                        style={{
                          width: "100px",
                          height: "auto",
                          borderRadius: "10px",
                        }}
                      />
                      <p
                        style={{
                          marginTop: "8px",
                          textAlign: "center",
                          fontSize: "14px",
                          lineHeight: "1.4",
                          color: "white",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          Play a practice game
                        </span>{" "}
                      </p>
                    </button>
                  </div>
                </div>
                <CenteredContainer>
                  <ResponsiveButton
                    onClick={() => {
                      setIsOpen(false);
                      localStorage.setItem("panelMinimized", "true");
                    }}
                  >
                    DON&apos;T SHOW AGAIN
                  </ResponsiveButton>
                </CenteredContainer>
              </div>
            ) : (
              <div style={{ padding: "0px" }}>
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: "16px",
                    fontSize: "17px",
                    marginTop: "10px",
                  }}
                >
                  Cryptic DJ turns random music knowledge into a word game.
                </div>
                <div
                  style={{
                    textAlign: "left",
                    marginBottom: "16px",
                    fontSize: "15px",
                    padding: "5px",
                  }}
                >
                  Guess the songs quickly, then spot what links the words you
                  see, it&#39;s...
                </div>
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: "16px",
                    position: "relative",
                  }}
                >
                  <img
                    src={aboutImg}
                    alt="About Cryptic DJ"
                    style={{ width: "100%", maxWidth: "235px", height: "auto" }}
                  />
                </div>
                <div
                  style={{
                    textAlign: "left",
                    marginBottom: "16px",
                    fontSize: "15px",
                    padding: "5px",
                  }}
                >
                  A mix of music rediscovery and mental agility, once you’ve
                  played Cryptic DJ you’ll never listen to the radio the same
                  way again.
                </div>
                <div
                  style={{
                    textAlign: "left",
                    marginBottom: "16px",
                    fontSize: "15px",
                    padding: "5px",
                  }}
                >
                  Our questions are curated by wordy music fans, if you have
                  ideas for questions or new features, please get in touch via
                  our social media channels:
                </div>
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: "10px",
                    position: "relative",
                  }}
                >
                  <ul
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "16px",
                      listStyleType: "none",
                      padding: 0,
                    }}
                  >
                    <li>
                      <a
                        href="https://www.instagram.com/crypticdj/"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={handleInstagramClick}
                      >
                        <svg
                          width="37"
                          height="38"
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4 0.533325C1.79086 0.533325 0 2.32418 0 4.53332V16.4495C0 18.6586 1.79086 20.4495 4 20.4495H15.9161C18.1253 20.4495 19.9161 18.6586 19.9161 16.4495V4.53333C19.9161 2.32419 18.1253 0.533325 15.9161 0.533325H4Z"
                            fill="url(#paint0_linear_2007_1164)"
                          />
                          <path
                            d="M13.3867 6.09375C12.9598 6.09375 12.604 6.44952 12.604 6.87644C12.604 7.30336 12.9598 7.65913 13.3867 7.65913C13.8136 7.65913 14.1694 7.30336 14.1694 6.87644C14.1694 6.44952 13.8136 6.09375 13.3867 6.09375Z"
                            fill="white"
                          />
                          <path
                            d="M9.90003 7.08984C8.12119 7.08984 6.62695 8.58407 6.62695 10.3629C6.62695 12.1418 8.12119 13.636 9.90003 13.636C11.6789 13.636 13.1731 12.1418 13.1731 10.3629C13.1731 8.51292 11.75 7.08984 9.90003 7.08984ZM9.90003 12.4264C8.76157 12.4264 7.83657 11.5014 7.83657 10.3629C7.83657 9.22445 8.76157 8.29945 9.90003 8.29945C11.0385 8.29945 11.9635 9.22445 11.9635 10.3629C11.9635 11.5014 11.1096 12.4264 9.90003 12.4264Z"
                            fill="white"
                          />
                          <path
                            d="M12.533 16.9802H7.19652C4.99076 16.9802 3.21191 15.2014 3.21191 12.9956V7.73022C3.21191 5.52445 4.99076 3.74561 7.19652 3.74561H12.4619C14.6677 3.74561 16.4465 5.52445 16.4465 7.73022V12.9956C16.5177 15.2014 14.7388 16.9802 12.533 16.9802ZM7.19652 4.95522C5.70229 4.95522 4.49268 6.16483 4.49268 7.65906V12.9244C4.49268 14.4187 5.70229 15.6283 7.19652 15.6283H12.4619C13.9561 15.6283 15.1657 14.4187 15.1657 12.9244V7.65906C15.1657 6.16483 13.9561 4.95522 12.4619 4.95522H7.19652Z"
                            fill="white"
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_2007_1164"
                              x1="-0.501304"
                              y1="0.0399983"
                              x2="20.3398"
                              y2="20.881"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop offset="0.0011048" stopColor="#FFE185" />
                              <stop offset="0.2094" stopColor="#FFBB36" />
                              <stop offset="0.3765" stopColor="#FF5176" />
                              <stop offset="0.5238" stopColor="#F63395" />
                              <stop offset="0.7394" stopColor="#A436D2" />
                              <stop offset="1" stopColor="#5F4EED" />
                            </linearGradient>
                          </defs>
                        </svg>
                      </a>
                    </li>

                    <li>
                      <a
                        href="https://www.facebook.com/people/Cryptic-DJ/61561112885076/"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={handleFacebookClick}
                      >
                        <svg
                          width="37"
                          height="39"
                          viewBox="0 0 24 23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.6162 22.9999C17.9674 22.9999 23.1161 17.8512 23.1161 11.5C23.1161 5.1487 17.9674 0 11.6162 0C5.26492 0 0.116211 5.1487 0.116211 11.5C0.116211 17.8512 5.26492 22.9999 11.6162 22.9999Z"
                            fill="#127AF6"
                          />
                          <path
                            d="M12.901 18.8881V12.4635H15.0211L15.4065 9.82949H12.8367V8.09485C12.8367 7.38815 13.2222 6.68145 14.3144 6.68145H15.4708V4.43285C15.4708 4.43285 14.4428 4.24011 13.4149 4.24011C11.2948 4.24011 9.94565 5.52503 9.94565 7.83787V9.82949H7.63281V12.4635H9.94565V18.8881H12.901Z"
                            fill="white"
                          />
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://x.com/TheCrypticDJ"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={handleXClick}
                      >
                        <svg
                          width="37"
                          height="39"
                          viewBox="0 0 24 23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.0161 22.9999C18.3673 22.9999 23.516 17.8512 23.516 11.5C23.516 5.1487 18.3673 0 12.0161 0C5.66482 0 0.516113 5.1487 0.516113 11.5C0.516113 17.8512 5.66482 22.9999 12.0161 22.9999Z"
                            fill="url(#paint0_linear_2007_1153)"
                          />
                          <path
                            d="M12.9801 10.6648L16.7706 6.23181H15.8711L12.5304 10.0865L9.89629 6.23181H6.8125L10.7957 12.0782L6.8125 16.7038H7.71194L11.1812 12.6564L14.008 16.7038H17.0918L12.9801 10.6648ZM11.7594 12.0782L11.3739 11.4999L8.16166 6.87427H9.57506L12.2091 10.6005L12.5946 11.1787L15.9996 15.9971H14.5862L11.7594 12.0782Z"
                            fill="white"
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_2007_1153"
                              x1="3.89421"
                              y1="3.33332"
                              x2="20.1756"
                              y2="19.6147"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stopColor="#23004A" />
                              <stop offset="0.9565" stopColor="#020003" />
                              <stop offset="1" />
                            </linearGradient>
                          </defs>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  style={{
                    position: "relative",
                    textAlign: "center",
                    paddingTop: "18px",
                    paddingBottom: "18px",
                    fontSize: "15px",
                    backgroundColor: "#251b5a",
                  }}
                >
                  Copyrights are acknowledged and royalties are proudly paid;
                  every song you stream on Cryptic DJ generates payments to the
                  rightsholders.
                </div>
                <div
                  style={{
                    position: "relative",

                    textAlign: "center",
                    paddingBottom: "22px",
                    backgroundColor: "#251b5a",
                  }}
                >
                  <img
                    src={prs}
                    alt="PRS Logo"
                    style={{ width: "100%", maxWidth: "80px", height: "auto" }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",

                    textAlign: "center",
                    fontSize: "12px",
                    paddingBottom: "30px",
                    backgroundColor: "#251b5a",
                  }}
                >
                  © Vipamedia Ltd 2025
                </div>
                <CookieText>
                  Our website uses cookies to perform gameplay functions and
                  provide traffic statistics - no personally identifiable
                  information is collected. Use of our website constitutes
                  acceptance of this.
                </CookieText>
              </div>
            )}
          </PanelContent>
        )}
      </Measure>
    </PanelContainer>
  );
};

export default NavigationPanel;
