import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";

export const PlayContentContainer = styled(Box)`
  position: relative;
  background: ${selectedTheme.colors.background};
  height: 100%;
  padding: 12px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 99%;
`;
export const GuessesContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
export const ClueButton = styled(Box)`
  display: flex;
  justify-content: center;
  gap: 5px;
  height: 56px;
  & > div:nth-child(1) {
    min-height: 56px;
    min-width: 56px;
    height: 56px;
    width: 56px;
  }
`;
export const GameDescription = styled(Box)`
  font-family: Calibri, sans-serif;
  font-size: 17px;
  text-align: center;
  color: white;
  margin: 24px 0;
  margin-top: 16px;
  font-weight: 300;
  @media (max-width: 399px) {
    font-size: 16.5px;
  }
`;
export const LinkGuessContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 16px;
  width: 100%;
`;
export const HelpAboutContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
`;
