import React, { useEffect, useRef, useState, useMemo } from "react";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase/firebaseConfig";
import PropTypes from "prop-types";
import {
  GameDescription,
  GuessesContainer,
  PlayContentContainer,
  HelpAboutContainer,
} from "./PlayContent.styled";
import PlayerLives from "../../components/PlayerLives/PlayerLives";
import GameTitle from "../../components/GameTitle/GameTitle";
import GuessSongField from "../../components/GuessSongField/GuessSongField";
import { useDispatch, useSelector } from "react-redux";
import { selectGame } from "../../store/selectors/gameSelectors";
import { fetchGame } from "../../store/actions/game/gameActions";
import LinkTextField from "../../components/TextField/LinkTextField/LinkTextField";
import { useTranslation } from "react-i18next";
import DetailsIcon from "../../components/DetailsIcon/DetailsIcon";
import DetailsCard from "../../components/DetailsCard/DetailsCard";
import PlayerController2 from "../../components/PlayerController/PlayerController2";
import FooterHistory from "../../components/Footer/FooterHistory";
import Sponsor from "../../components/Sponsor/Sponsor";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import NavigationPanel from "../../components/NavigationPanel/NavigationPanel";

import gameStates from "../../constants/gameStates";

const PlayContent = () => {
  const { t } = useTranslation();
  const game = useSelector(selectGame); // Povlačenje niza kvizova iz Redux store-a
  const dispatch = useDispatch();

  const { directory, index } = useParams();
  const currentGameIndex = index ? parseInt(index, 10) - 1 : 0; // Ako nema `index`, podrazumevano je prvi kviz
  const currentGame = game && game.length > 0 ? game[currentGameIndex] : null; // Trenutni kviz
  const history = useHistory();

  const [hints, setHints] = useState({});
  const [graphics, setGraphics] = useState({});

  const songRefs = useRef([null, null, null, null]);
  const [lives, setLives] = useState(30);
  const [scoreTimers, setScoreTimers] = useState([0, 0, 0, 0, 0]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const timerStatus = useRef(false);
  const [solvedFields, setSolvedFields] = useState(0);
  const [cluesRevealed, setCluesRevealed] = useState(false);
  const [titleOrArtistCluesRevealed, setTitleOrArtistCluesRevealed] =
    useState(false);
  const [toggleClues, setToggleClues] = useState(false);
  const [toggleTitleOrArtistClues, setToggleTitleOrArtistClues] =
    useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [activePlayer, setActivePlayer] = useState([true, false, false, false]); // Samo jedan plejer može biti aktivan
  const [cardType, setCardType] = useState("");
  const [currentSongUrl, setCurrentSongUrl] = useState("");
  const [decimalTime, setDecimalTime] = useState(0);
  const [decimalInterval, setDecimalInterval] = useState(-1);
  const PlayerController2Ref = useRef();
  const [songForFun, setSongForFun] = useState([false, false, false, false]);
  const [isShowTimer, setIsShowTimer] = useState(false);
  const [isLoadedSongs, setIsLoadedSongs] = useState([
    false,
    false,
    false,
    false,
  ]);

  const [answersTimeOutStatus, setAnswersTimeOutStatus] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  const [isGaveUpFields, setIsGaveUpFields] = useState([
    false,
    false,
    false,
    false,
  ]);

  const [gameState, setGameState] = useState(gameStates.INITIAL);

  // Prati prethodni URL za osvežavanje
  const prevLocationRef = useRef(history.location.pathname);

  const [canPlay, setCanPlay] = useState(true);
  const [message, setMessage] = useState("");
  const [sessionPlayedSongs, setSessionPlayedSongs] = useState(0);
  //COMMENTED ALREADY PLAYED
  // const checkPlayStatus = () => {
  //   const quizKey = `quiz_${directory}_${index}`;
  //   const today = new Date().toISOString().split("T")[0];

  // const quizData = JSON.parse(localStorage.getItem(quizKey)) || {
  //   date: today,
  //   playedSongs: 0,
  // };

  // Resetuj broj pesama ako je datum stariji
  // if (quizData.date !== today) {
  //   quizData.date = today;
  //   quizData.playedSongs = 0;
  //   localStorage.setItem(quizKey, JSON.stringify(quizData));
  //   setSessionPlayedSongs(0); // Reset sesijskog stanja
  //   setCanPlay(true);
  //   return;
  // }

  // Ako je broj pesama >= 3 i prozor je osvežen, blokiraj
  //   if (quizData.playedSongs >= 3 && sessionPlayedSongs === 0) {
  //     setCanPlay(false);
  //     setMessage("You already played");
  //   } else {
  //     setCanPlay(true);
  //   }
  // };
  //Commented play today!!!
  // const updatePlayStatus = () => {
  //   const quizKey = `quiz_${directory}_${index}`;
  //   const quizData = JSON.parse(localStorage.getItem(quizKey)) || {
  //     date: new Date().toISOString().split("T")[0],
  //     playedSongs: 0,
  //   };

  //   // Povećaj broj pesama u localStorage
  //   quizData.playedSongs += 1;
  //   localStorage.setItem(quizKey, JSON.stringify(quizData));

  //   // Povećaj broj pesama u trenutnoj sesiji
  //   setSessionPlayedSongs((prev) => prev + 1);
  // };

  // useEffect(() => {
  //   checkPlayStatus();
  // }, [directory, index, sessionPlayedSongs]);

  useEffect(() => {
    if (currentGame && currentGame.id) {
      logEvent(analytics, "Game_ID", {
        game_id: currentGame.id,
        debug_mode: true, // Debug mod za praćenje
      });
    }
  }, [currentGame]);

  const trackDeviceEvent = () => {
    const userAgent = navigator.userAgent;
    const platform = navigator.platform;

    // Jednostavna analiza za pretraživač
    const browser = /Chrome/.test(userAgent)
      ? "Chrome"
      : /Firefox/.test(userAgent)
      ? "Firefox"
      : /Safari/.test(userAgent) && !/Chrome/.test(userAgent)
      ? "Safari"
      : /Edge/.test(userAgent)
      ? "Edge"
      : "Unknown";

    // Jednostavna analiza za operativni sistem
    const os = /Win/.test(platform)
      ? "Windows"
      : /Mac/.test(platform)
      ? "MacOS"
      : /Linux/.test(platform)
      ? "Linux"
      : /Android/.test(userAgent)
      ? "Android"
      : /iPhone|iPad|iPod/.test(userAgent)
      ? "iOS"
      : "Unknown";

    const deviceInfo = {
      browser,
      os,
      platform,
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
    };

    logEvent(analytics, "Device_info", {
      ...deviceInfo,
      debug_mode: true,
    });
  };
  useEffect(() => {
    trackDeviceEvent();
  }, []);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === "POP") {
        // Uvek osveži stranicu za `POP` akciju
        prevLocationRef.current = location.pathname;
        window.location.reload(); // Osveži stranicu
      } else {
        prevLocationRef.current = location.pathname; // Ažuriraj za PUSH i druge akcije
      }
    });

    // Čisti listener kada komponenta izađe iz DOM-a
    return () => {
      unlisten();
    };
  }, [history]);

  // useEffect za preuzimanje igre - COMMENTS FOR PLAYED TODAY!!!!
  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    // const isPlayed = localStorage.getItem("played");
    // console.log("Loading game data...");

    // if (!isPlayed) {
    //   setCardType("help");
    //   localStorage.setItem("played", JSON.stringify(true));
    // }

    const payload = directory
      ? { directoryName: directory, date: today }
      : { date: today };

    dispatch(fetchGame(payload));
  }, [directory, dispatch]);

  // useEffect za postavljanje hints i graphics
  useEffect(() => {
    if (
      JSON.stringify(hints) === "{}" &&
      currentGame &&
      currentGame.songs &&
      currentGame.songs.length > 0
    ) {
      const hints_data = {
        things_or_phrases: currentGame.songs[0].hints.find(
          (hint) => hint.name === "Things or Phrases"
        ),
        title_or_artist: currentGame.songs.map(
          (song) =>
            song.hints.find((hint) => hint.name === "Title or Artist")?.hint
        ),
        highlight_words: currentGame.songs.map(
          (song) =>
            song.hints.find((hint) => hint.name === "Highlight Word")?.hint
        ),
      };
      setIsShowTimer(currentGame.shouldShowTimer);

      setHints(hints_data);
      // console.log("GAME OBJECT:", currentGame);
      // console.log("GAME ID", currentGame.id);

      // console.log("HINTS DATA OBJECT:", hints_data);

      // console.log("HINTS OBJECT:", hints);
    }
    if (currentGame && currentGame.graphics) {
      const {
        footerLogoUrl = "",
        footerText = "",
        masterHead = "",
        primaryLogoUrl = "",
        secondaryLogoUrl = "",
        primaryText = "",
      } = currentGame.graphics;
      setGraphics({
        footerLogoUrl,
        footerText,
        masterHead,
        primaryLogoUrl,
        secondaryLogoUrl,
        primaryText,
      });
    }
  }, [currentGame, hints]);

  useEffect(() => {
    handleClearInterval();
  }, [answersTimeOutStatus]);

  const loseLife = () => {
    // Poziva se iz child kao prop
    if (lives > 0) setLives((prevLives) => prevLives - 1);
  };

  const playSongForFun = (index) => {
    setSongForFun((prevState) =>
      prevState.map((el, idx) => (idx === index ? true : el))
    );
  };

  const updateScoreTimers = (time, index) => {
    // Ažurira scoreTimer sa vremenom pesme
    setScoreTimers((prevState) =>
      prevState.map((el, idx) => {
        if (idx === index) {
          if (el <= time) {
            return time;
          } else {
            return el;
          }
        } else {
          return el;
        }
      })
    );
  };

  const giveUpScoreTimers = (index) => {
    setScoreTimers((prevState) => {
      return prevState.map((el, idx) => {
        if (idx === index) {
          return (el += 20);
        } else {
          return el;
        }
      });
    });
  };

  const scoreTimer = useMemo(() => {
    let score = Math.floor(scoreTimers.reduce((sum, el) => (sum += el)));
    return score;
  }, [scoreTimers]);

  const updateTimerStatus = () => {
    if (timerStatus.current) return;
    timerStatus.current = true;
  };

  const solveField = () => {
    // Poziva se iz child kao prop
    setSolvedFields((prevSolvedFields) => prevSolvedFields + 1);
  };

  const revealClues = () => {
    // Poziva se iz child kao prop
    if (!cluesRevealed && !gameOver) {
      setLives((prevLives) => prevLives - 3);
      setCluesRevealed(true);
      setScoreTimers((prevState) =>
        prevState.map((el, idx) => {
          if (idx === prevState.length - 1) {
            if (el === 0) {
              return (el += 10);
            }
          } else {
            return el;
          }
        })
      );
    }
    setToggleClues((prevState) => !prevState);
  };

  const revealTitleOrArtistClues = () => {
    if (!titleOrArtistCluesRevealed && !gameOver) {
      setLives((prevLives) => prevLives - 3);
      setTitleOrArtistCluesRevealed(true);
    }
  };

  const revealClueHint = () => {
    if (solvedFields < 3) return;
    setCluesRevealed(true);
    setToggleClues(true);
  };

  const revealTitleOrArtistClueHint = () => {
    if (solvedFields < 3) return;
    setTitleOrArtistCluesRevealed(true);
    setToggleTitleOrArtistClues(true);
  };

  const revealHints = (addingTime) => {
    if (!gameOver && addingTime) {
      const newScoreTimers = scoreTimers.map((item) => item);
      newScoreTimers.push(Number(addingTime));
      setScoreTimers(newScoreTimers);
    }
  };
  //Commented PLayTOday!!!!!
  const playSong = (indexOfSong) => {
    // if (!canPlay && window.location.pathname !== "/example") return;

    // Pokreni pesmu i ažuriraj stanje
    songRefs?.current?.forEach((singleSongRef, index) => {
      if (index !== indexOfSong) {
        singleSongRef.pauseSong();
        singleSongRef.song.pause();
      }
    });
    // updatePlayStatus(); // Ažuriraj stanje nakon puštanja pesme
  };

  const togglePlayers = (index) => {
    // Skrivanje svih plejera osim onog koji je kliknut
    setActivePlayer(
      activePlayer.map((el, idx) => {
        if (idx !== index) {
          return (el = false);
        } else {
          return (el = true);
        }
      })
    );
    playSong(index);
  };

  const handleSetDecimalInterval = () => {
    if (decimalInterval !== null && decimalInterval > 0) {
      return;
    }
    setDecimalInterval(
      setInterval(() => {
        setDecimalTime((prevState) => {
          return (prevState + 1) % 10;
        });
      }, 100)
    );
  };

  const handleClearInterval = () => {
    if (decimalInterval) {
      clearInterval(decimalInterval);
      setDecimalInterval(null);
    }
  };

  const setDecimalTimeToZero = () => {
    setDecimalTime(0);
  };

  const handleTimeUpController = () => {
    handleClearInterval();
    setDecimalTimeToZero();
  };

  useEffect(() => {
    if (gameOver) {
      setCluesRevealed(true);
      setToggleClues(true);
    }
  }, [gameOver]);

  const handleSetGameState = (state) => {
    setGameState(state);
  };

  const pauseMusic = () => {
    if (PlayerController2Ref.current) {
      PlayerController2Ref.current.onPauseChildren();
    }
  };

  const stopForFunSongs = () => {
    songForFun.forEach((isForFun, index) => {
      if (isForFun) {
        const songRef = songRefs.current[index];
        if (songRef && songRef.song instanceof HTMLAudioElement) {
          songRef.song.pause();
          songRef.song.currentTime = 0; // Reset to start position

          // Temporarily clear the src to force reload
          const originalSrc = songRef.song.src;
          songRef.song.src = "";
          songRef.song.src = originalSrc; // Reassign src for the next playback
        }
        setSongForFun((prevState) =>
          prevState.map((el, idx) => (idx === index ? false : el))
        );
      }
    });
  };

  // const handleNextGame = () => {
  //   if (currentGameIndex < game.length - 1) {
  //     // Stop any ongoing 'for fun' songs before moving to the next quiz
  //     stopForFunSongs();

  //     setCurrentGameIndex(currentGameIndex + 1);
  //     resetGameState();

  //     // Set focus and preload for the first song in the new quiz

  //     setCurrentIndex(0); // Ensure the first song is selected in the new quiz
  //   }
  // };

  //Here we make the URL for separate quiz in the sam directory!!! plus handle next game
  const handleNextGame = () => {
    if (currentGameIndex < game.length - 1) {
      stopForFunSongs();

      const nextIndex = currentGameIndex + 2; // "+ 2" jer je indeks u URL-u baziran na 1
      const nextPath = directory
        ? `/${directory}/${nextIndex}`
        : `/${nextIndex}`;

      history.push(nextPath); // Navigacija na sledeći kviz
      resetGameState();
      setCurrentIndex(0);
    }
  };

  // const handlePrevGame = () => {
  //   if (currentGameIndex > 0) {
  //     setCurrentGameIndex(currentGameIndex - 1);
  //     // Resetovanje stanja igre
  //     resetGameState();
  //   }
  // };

  const resetGameState = () => {
    setHints({});
    setGraphics({});
    setLives(30);
    setScoreTimers([0, 0, 0, 0, 0]);
    setSolvedFields(0);
    setCluesRevealed(false);
    setTitleOrArtistCluesRevealed(false);
    setToggleClues(false);
    setToggleTitleOrArtistClues(false);
    setGameOver(false);
    setIsPlaying(false);
    setActivePlayer([true, false, false, false]);
    // setCurrentSongUrl("");
    setDecimalTime(0);
    setDecimalInterval(-1);
    setIsShowTimer(false);
    setIsLoadedSongs([false, false, false, false]);
    setAnswersTimeOutStatus([false, false, false, false, false]);
    setIsGaveUpFields([false, false, false, false]);
    setGameState(gameStates.INITIAL);
  };
  if (!canPlay && window.location.pathname !== "/example") {
    return <h1 style={{ color: "white" }}>You Already Played Today!</h1>;
  } else
    return (
      <PlayContentContainer>
        <GameTitle graphics={graphics} />
        <PlayerLives
          noOfLives={lives}
          isPlaying={isPlaying}
          gameOver={gameOver}
          scoreTimer={scoreTimer}
          timerStatus={timerStatus.current}
          decimalTime={decimalTime}
          gameState={gameState}
          isShowTimer={isShowTimer}
        />
        <GameDescription>
          {/* {t("game.description")} */}
          {t("game.description")}
        </GameDescription>
        <GuessesContainer onClick={updateTimerStatus}>
          {JSON.stringify(hints) !== "{}" &&
            currentGame?.songs?.map((singleSong, index) => {
              return (
                <GuessSongField
                  totalFields={currentGame?.songs?.length}
                  songs={currentGame?.songs}
                  cluesRevealed={toggleClues}
                  titleOrArtistCluesRevealed={toggleTitleOrArtistClues}
                  loseLife={loseLife}
                  playSong={playSong}
                  setIsPlaying={setIsPlaying}
                  isPlaying={isPlaying}
                  disabled={gameOver || isGaveUpFields[index]}
                  ref={(elRef) => (songRefs.current[index] = elRef)}
                  solveField={solveField}
                  key={singleSong?.id}
                  songUrl={singleSong?.url}
                  songIndex={index}
                  hint={hints.highlight_words[index]}
                  titleOrArtistHint={hints.title_or_artist[index]}
                  songName={singleSong?.name}
                  displayName={singleSong?.displayName}
                  artist={singleSong?.artist}
                  songId={singleSong?.id}
                  activePlayer={activePlayer}
                  togglePlayers={togglePlayers}
                  scoreTimers={scoreTimers[index]}
                  updateScoreTimers={updateScoreTimers}
                  giveUpScoreTimers={giveUpScoreTimers}
                  handleSetDecimalInterval={handleSetDecimalInterval}
                  handleClearInterval={handleClearInterval}
                  setDecimalTimeToZero={setDecimalTimeToZero}
                  setCurrentSongUrl={setCurrentSongUrl}
                  currentIndex={currentIndex}
                  setCurrentIndex={setCurrentIndex}
                  pauseMusic={pauseMusic}
                  isLoaded={isLoadedSongs[index]}
                  answersTimeOutStatus={answersTimeOutStatus}
                  setAnswersTimeOutStatus={setAnswersTimeOutStatus}
                  isGaveUpFields={isGaveUpFields}
                  songForFun={songForFun}
                  setSongForFun={setSongForFun}
                  songRefs={songRefs}
                />
              );
            })}
          {!gameOver && currentGame?.songs?.length > 0 && solvedFields < 4 && (
            <PlayerController2
              disabled={gameOver}
              ref={PlayerController2Ref}
              currentSongUrl={currentSongUrl}
              handleSetDecimalInterval={handleSetDecimalInterval}
              handleClearInterval={handleClearInterval}
              setDecimalTimeToZero={setDecimalTimeToZero}
              scoreTimers={scoreTimers}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
              updateScoreTimers={updateScoreTimers}
              giveUpScoreTimers={giveUpScoreTimers}
              setIsLoadedSongs={setIsLoadedSongs}
              isLoadedSongs={isLoadedSongs}
              answersTimeOutStatus={answersTimeOutStatus}
              setAnswersTimeOutStatus={setAnswersTimeOutStatus}
              handleTimeUpController={handleTimeUpController}
              setIsPlaying={setIsPlaying}
              isGaveUpFields={isGaveUpFields}
              setIsGaveUpFields={setIsGaveUpFields}
              songForFun={songForFun}
              setSongForFun={setSongForFun}
              songRefs={songRefs}
              displayName={currentGame?.songs?.[currentIndex]?.displayName}
            />
          )}
        </GuessesContainer>
        <LinkTextField
          answers={currentGame?.answers}
          loseLife={loseLife}
          totalLives={lives}
          questionId={currentGame?.id}
          gameOver={() => setGameOver(true)}
          revealClues={revealClues}
          cluesRevealed={cluesRevealed}
          titleOrArtistCluesRevealed={titleOrArtistCluesRevealed}
          revealTitleOrArtistClues={revealTitleOrArtistClues}
          solvedFields={solvedFields}
          scoreTimer={scoreTimer}
          handleSetGameState={handleSetGameState}
          decimalTime={decimalTime}
          disabled={gameOver}
          revealHints={revealHints}
          hints={hints}
          revealClueHint={revealClueHint}
          revealTitleOrArtistClueHint={revealTitleOrArtistClueHint}
        />

        {(gameState === gameStates.WON || gameState === gameStates.LOST) && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "26px",
              gap: "10px",
            }}
          >
            {currentGameIndex < game.length - 1 && (
              <button
                onClick={handleNextGame}
                style={{
                  color: "black",
                  backgroundColor: "white",
                  border: "none",
                  padding: "10px",
                  borderRadius: "8px",
                  fontWeight: "bold",
                }}
              >
                Next Challenge
              </button>
            )}
          </div>
        )}

        {cardType === "help" && (
          <DetailsCard cardType="help" setCardType={setCardType} />
        )}
        {cardType === "submit" && (
          <DetailsCard cardType="submit" setCardType={setCardType} />
        )}
        {cardType === "about" && (
          <DetailsCard cardType="about" setCardType={setCardType} />
        )}
        {cardType === "score" && (
          <DetailsCard
            cardType="score"
            // score={finalScore}
            gameState={gameState}
          />
        )}
        <div
          style={{
            borderTop: "2px solid #fff",
            paddingTop: "20px",
            marginTop: "26px",
          }}
        >
          {(gameState === gameStates.WON || gameState === gameStates.LOST) && (
            <FooterHistory />
          )}
          {/* <HelpAboutContainer>
          <DetailsIcon
            cardType="help"
            setCardType={setCardType}
            // blockingGame={blockingGame}
          />
          <DetailsIcon
            cardType="submit"
            setCardType={setCardType}
            // blockingGame={blockingGame}
          />
          <DetailsIcon
            cardType="about"
            setCardType={setCardType}
            // blockingGame={blockingGame}
          />
        </HelpAboutContainer> */}
        </div>
        <Sponsor graphics={graphics} />
        <NavigationPanel />
      </PlayContentContainer>
    );
};

PlayContent.propTypes = {
  children: PropTypes.node,
};

export default PlayContent;
